.restartDIV{
    position: absolute;
    top: 2vh;
    left: calc(95%);
}

.restartImg{
    width: 3vw;
    height: 7vh;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.restartDIV :hover{
    width: 4vw;
    height: 8vh;
}

.restartDIV :active{
    width: 4vw;
    height: 8vh;
    transform: translateY(4px);
}