@font-face {
    font-family: 'scoreFont';
    src: url('../fonts/GWIBBLE.TTF') ;
  }

  .countdown{
    font-family: 'scoreFont';
    color:red;
    position: absolute;
    top: 30vh;
    left: 45vw;
    font-size: 20vw; 

}