body{
  overflow: hidden !important;
}

.backgroundImage {
  text-align: center;
  width: 100%;
  height: 100vh;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}


