.pauseDIV{
    position: absolute;
    top: 2vh;
    left: calc(91%);
}

.pauseImg{
    width: 3vw;
    height: 7vh;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.pauseDIV :hover{
    width: 4vw;
    height: 8vh;
    transform: translateX(-1vw); 
}

.pauseDIV :active{
    width: 4vw;
    height: 8vh;
    transform: translateY(4px) translateX(-1vw);   
}