@font-face {
    font-family: 'scoreFont';
    src: url('../fonts/GWIBBLE.TTF') ;
  }

.gameOverDIV{
    font-family: 'scoreFont';
    color:red;
    position: absolute;
    top: calc(100%/2.5);
    left: calc(100%/4.3);
}

.gameOverText{
     font-size: 10vw; 
}
