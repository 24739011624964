@font-face {
    font-family: 'scoreFont';
    src: url('../fonts/GWIBBLE.TTF') ;
  }

.lifeDIV{
    font-family: 'scoreFont';
    color:red;
    position: absolute;
    top: 1.5vh;
    left: calc(45%);
}

.lifeImg{
    width: 3vw;
    height: 7vh;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.lifeText{
    font-size: 3vw; 
    vertical-align: text-bottom;
}

.lifeValue{
    font-size: 4vw; 
}