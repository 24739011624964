@font-face {
    font-family: 'scoreFont';
    src: url('../fonts/GWIBBLE.TTF') ;
  }

.scoreDIV{
    font-family: 'scoreFont';
    color:red;
    position: absolute;
    top: 0.5vh;
    left: calc(100%/80);
}

.scoreText{
     font-size: 3vw; 
}

.scoreValue{
    font-size: 4vw; 
}